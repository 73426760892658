@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@font-face {
  font-family: CenturyGothic;
  src: url(/static/media/GOTHIC.ab135eb4.woff);
}

@font-face {
  font-family: CenturyGothic-Bold;
  src: url(/static/media/GOTHICB.a3fde714.woff);
}

@font-face {
  font-family: CenturyGothic-Bold-Italic;
  src: url(/static/media/GOTHICBI.f2dc73c0.woff);
}

@font-face {
  font-family: CenturyGothic-Italic;
  src: url(/static/media/GOTHICI.bc9aa56b.woff);
}

:root {
  --color-main: #EF1131;
  --color-backGround: #D30000EE;
  --color-darkMain: #0009;
  --color-tyc: #FFF;
  --color-textLinks: #ff0000;
  --color-tycText: #020202;
  --color-autoComplete: #ffb4be;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Condensed', sans-serif;
  color: #FFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: #000;
}


body #root {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: #5554;
}
*::-webkit-scrollbar-thumb {
  background: #EF1131;
  background: var(--color-main);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #0009;
  background: var(--color-darkMain);
}

.swal2-popup {
  border-radius: 20px !important;
}

.swal2-title {
  padding: 0px !important;
}

.swal2-title br {
  font-size: 0 !important;
}

.swal2-image {
  margin: 3vw auto 1vw auto !important;
}

.closePopUp {
  position: absolute;
  top: -20%;
}

button.swal2-close {
  color: #FFF;
}

